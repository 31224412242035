import { useState } from "react";
import { AuthRequest, AuthResponse, Ingredient, Recipe } from "./model";

export const RecipeForm = () => {
  const [recipe, setRecipe] = useState<Recipe>({
    name: "",
    category: "Hauptgerichte",
    effort: "S",
    ingredients: "",
    instructions: "",
    persons: "4",
  });
  const [ingredient, setIngredient] = useState<Ingredient>({ name: "", quantity: "", unit: "g" });
  const [ingredients, setIngredients] = useState<Array<Ingredient>>([]);
  const [result, setResult] = useState<string>("");

  const auth = async (): Promise<string> => {
    const request: AuthRequest = { identity: "admin@annabellekocht.de", password: "super-geheimes-kennwort" };
    const result = await fetch("https://annabellekocht.kinkyconsulting.de/api/admins/auth-with-password", {
      method: "POST",
      body: JSON.stringify(request),
      headers: { "Content-Type": "application/json" },
    });
    const response: AuthResponse = await result.json();
    return response.token;
  };

  const submit = async () => {
    const token = await auth();
    const data = new FormData();
    data.append("name", recipe.name);
    data.append("category", recipe.category);
    data.append("label", recipe.label || "");
    data.append("effort", recipe.effort);
    data.append("ingredients", JSON.stringify(ingredients));
    data.append("instructions", recipe.instructions);
    data.append("persons", recipe.persons);
    data.append("image", recipe.image || "");
    const result = await fetch("https://annabellekocht.kinkyconsulting.de/api/collections/recipes/records", {
      method: "POST",
      body: data,
      headers: { Authorization: `Bearer ${token}` },
    });

    if (result.status === 200) {
      setRecipe({
        name: "",
        category: "Hauptgerichte",
        effort: "S",
        ingredients: "",
        instructions: "",
        persons: "4",
        image: undefined,
      });
      setIngredient({ name: "", quantity: "", unit: "g" });
      setIngredients([]);
      setResult(`Erfolgreich gespeichert (${result.status.toString()})`);
    } else {
      setResult(`Fehler (${result.status.toString()})`);
    }
  };

  const add = () => {
    setIngredients(ingredients.concat(ingredient));
    setIngredient({ name: "", quantity: "", unit: "g" });
  };

  const remove = (name: string) => {
    setIngredients(ingredients.filter((i) => i.name !== name));
  };

  return (
    <div className="container mx-auto w-fit">
      <div className="text-2xl mt-5 mb-10">Annabelle kocht - Publisher UI</div>
      <div className="grid grid-cols-2 gap-4">
        <div>Name:</div>
        <div>
          <input
            required
            value={recipe.name}
            type="text"
            placeholder="Gulsch"
            name="name"
            id="name"
            className="w-96"
            onChange={(e) => setRecipe({ ...recipe, name: e.currentTarget.value })}
          />
        </div>

        <div>Kategorie:</div>
        <div>
          <select value={recipe.category} className="w-96" onChange={(e) => setRecipe({ ...recipe, category: e.currentTarget.value })}>
            <option value={"Vorspeisen"}>Vorspeisen</option>
            <option value={"Hauptgerichte"}>Hauptgerichte</option>
            <option value={"Desserts"}>Desserts und Kuchen</option>
            <option value={"Brote"}>Brote</option>
            <option value={"Salate"}>Salate</option>
          </select>
        </div>

        <div>Label:</div>
        <div>
          <select value={recipe.label} className="w-96" onChange={(e) => setRecipe({ ...recipe, label: e.currentTarget.value })}>
            <option value={""}>(keins)</option>
            <option value={"Vegetarisch"}>Vegetarisch</option>
            <option value={"Vegan"}>Vegan</option>
            <option value={"Laktosefrei"}>Laktosefrei</option>
          </select>
        </div>

        <div>Aufwand:</div>
        <div>
          <select value={recipe.effort} className="w-96" onChange={(e) => setRecipe({ ...recipe, effort: e.currentTarget.value })}>
            <option value={"S"}>S</option>
            <option value={"M"}>M</option>
            <option value={"L"}>L</option>
            <option value={"XL"}>XL</option>
          </select>
        </div>

        <div>Für wieviele Personen:</div>
        <div>
          <select value={recipe.persons} className="w-96" onChange={(e) => setRecipe({ ...recipe, persons: e.currentTarget.value })}>
            <option value={"2"}>2</option>
            <option value={"3"}>3</option>
            <option value={"4"}>4</option>
            <option value={"5"}>5</option>
            <option value={"6"}>6</option>
            <option value={"8"}>8</option>
            <option value={"10"}>10</option>
          </select>
        </div>

        <div>Zutaten:</div>
        <div>
          {ingredients.map((i) => (
            <div key={i.name} className="flex mb-2 mt-2 bg-gray-100 items-center">
              <div className="w-32">{i.name}</div>
              <div className="w-24">{i.quantity}</div>
              <div className="w-24">{i.unit}</div>
              <div className="w-16">
                <button className="w-16 h-10" onClick={() => remove(i.name)}>
                  -
                </button>
              </div>
            </div>
          ))}
          <div>
            <input value={ingredient.name} type="text" placeholder="Tomaten" className="w-32" onChange={(e) => setIngredient({ ...ingredient, name: e.currentTarget.value })} />
            <input value={ingredient.quantity} type="text" placeholder="300" className="w-24" onChange={(e) => setIngredient({ ...ingredient, quantity: e.currentTarget.value })} />
            <select value={ingredient.unit} className="w-24" onChange={(e) => setIngredient({ ...ingredient, unit: e.currentTarget.value })}>
              <option value={"g"}>g</option>
              <option value={"kg"}>kg</option>
              <option value={"TL"}>TL</option>
              <option value={"EL"}>EL</option>
              <option value={"Cups"}>Cups</option>
              <option value={"ml"}>ml</option>
              <option value={"l"}>l</option>
              <option value={"Prise"}>Prise</option>
              <option value={"Stück"}>Stück</option>
              <option value={"Packung"}>Packung</option>
              <option value={"Tütchen"}>Tütchen</option>
              <option value={"Bund"}>Bund</option>
              <option value={"Messerspitze"}>Messerspitze</option>
              <option value={"Dose"}>Dose</option>
            </select>
            <button className="w-16 h-10 border border-inherit" onClick={add}>
              +
            </button>
          </div>
        </div>

        <div>Instruktionen:</div>
        <div>
          <textarea value={recipe.instructions} required className="w-96" rows={10} onChange={(e) => setRecipe({ ...recipe, instructions: e.currentTarget.value })} />
        </div>

        <div>Foto:</div>
        <div>
          <input required type="file" name="image" id="image" className="w-96" onChange={(e) => setRecipe({ ...recipe, image: e.target.files![0] })} />
        </div>

        <div>Status: {result}</div>
        <div>
          <button className="rounded-lg w-96 h-16 bg-green-300 mt-10" onClick={submit}>
            Speichern
          </button>
        </div>
      </div>
    </div>
  );
};
