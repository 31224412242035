import { RecipeForm } from "./recipe-form";

function App() {
  return (
    <div className="App">
      <RecipeForm />
    </div>
  );
}

export default App;
